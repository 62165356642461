import React from "react";
import "./nav.css";
import nav_logo from "../../assets/nav-logo.png";

function Nav() {
  return (
    <div className="nav">
      <a href="saasverse.tech">
        <img src={nav_logo} alt="SaaSVerse Logo" className="nav_logo" />
      </a>
      <a href="mailto:azad@saasverse.tech" className="nav_contact_us">
        <h5>Contact Us</h5>
      </a>
    </div>
  );
}

export default Nav;
