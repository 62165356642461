import React from "react";
import Typewriter from "typewriter-effect";
import { ReactNebula } from "@flodlc/nebula";
import "./Home.css";
import Nav from "./Nav";
function Home() {
  const currentYear = new Date().getFullYear();
  return (
    <div className="home">
      <Nav />
      <ReactNebula
        config={{
          starsCount: 0,
          starsColor: "#FFFFFF",
          starsRotationSpeed: 0,
          cometFrequence: 500,
          nebulasIntensity: 0,
          sunScale: 0,
          planetsScale: 0,
          solarSystemOrbite: 0,
          solarSystemSpeedOrbit: 0,
        }}
      />
      <div className="home_container">
        <div className="type1">
          <Typewriter
            onInit={(typewriter) => {
              typewriter
                .typeString("Introducing.....")
                .pauseFor(2500)
                .deleteChars(2)
                .start();
            }}
          />
        </div>
        <div className="type2">
          <Typewriter
            options={{
              autoStart: true,
              loop: true,
            }}
            onInit={(typewriter) => {
              typewriter
                .typeString("<h1 class='f1'><strong>SaaSVerse</strong></h1>")
                .pauseFor(1500)
                .deleteAll()
                .typeString("<h1 class='f2'><strong>SaaSVerse</strong></h1>")
                .pauseFor(1500)
                .deleteAll()
                .typeString("<h1 class='f3'><strong>SaaSVerse</strong></h1>")
                .pauseFor(500)
                .deleteAll()
                .typeString("<h1 class='f4'><strong>SaaSVerse</strong></h1>")
                .pauseFor(1500)
                .deleteAll()
                .typeString("<h1 class='f5'><strong>SaaSVerse</strong></h1>")
                .pauseFor(1500)
                .deleteAll()
                .typeString("<h1 class='f6'><strong>SaaSVerse</strong></h1>")
                .pauseFor(1500)
                .deleteAll()
                .typeString("<h1 class='f7'><strong>SaaSVerse</strong></h1>")
                .pauseFor(500)
                .deleteAll()
                .typeString("<h1 class='f8'><strong>SaaSVerse</strong></h1>")
                .pauseFor(1500)
                .deleteAll()
                .typeString("<h1 class='f9'><strong>SaaSVerse</strong></h1>")
                .start();
            }}
          />
        </div>
        <div className="type3">
          <Typewriter
            onInit={(typewriter) => {
              typewriter.typeString("your").start();
            }}
          />
          <Typewriter
            onInit={(typewriter) => {
              typewriter
                .pauseFor(100)
                .typeString("<h6>Digital</h6>")
                .pauseFor(1000)
                .deleteChars(8)
                .typeString("<h6>Tech</h6>")
                .pauseFor(2500)
                .deleteChars(4)
                .typeString("<h6>Software</h6> ")
                .start();
            }}
          />
          <Typewriter
            onInit={(typewriter) => {
              typewriter.typeString("Solution").start();
            }}
          />
        </div>
        <div className="footer_copright">
          <h5>Copyright &#169; {currentYear} | SaaSVerse Tech </h5>
        </div>
      </div>
    </div>
  );
}

export default Home;
