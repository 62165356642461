import React, { useState, useEffect } from "react";
import "./App.css";
import { infinity } from "ldrs"; // Importing the infinity loader
import Home from "./Components/Home";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Registering the infinity loader
    infinity.register("l-infinity");

    // Simulate a loading process for 5 seconds
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    // Clear the timer on component unmount or when isLoading becomes false
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="App">
      <h1 style={{display:'none'}}>SaaSVerse</h1>
      {isLoading ? (
        <div aria-live="polite" className="loader" aria-busy={isLoading}>
          <l-infinity
            color="#092537"
            size="300"
            stroke="15"
            speed="1"
          ></l-infinity>
        </div>
      ) : (
        <Home />
      )}
    </div>
  );
}

export default App;
